<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Type</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="product_typeInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Department</label>
                  <model-list-select
                    v-model="form.department_id"
                    placeholder="Select Department"
                    :list="departments"
                    option-value="id"
                    option-text="name"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.department_id">{{
                    errors.department_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormProductType",
  data() {
    return {
      name: "Form Product Type",
      form: {
        name: "",
        department_id: "",
        code: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
      departments: [],
    };
  },
  mounted() {
    this.getData();
    this.getDepartment();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("product_type/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDepartment() {
      apiGet("department")
        .then((result) => {
          this.departments = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    product_typeInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "product_type/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Product Type has been created!");
            this.$router.push({ name: "ProductType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;
        let id = this.$route.params.id;
        axios
          .post(
            this.$store.state.api + "product_type/update/" + id,
            this.form,
            {
              headers: {
                Authorization: this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$noty.success("Your paymentType has been updated!");
            this.$router.push({ name: "ProductType" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
